import FilesController from "./files_controller"

export default class extends FilesController {

  acceptedFiles() {
    return "image/jpeg,image/jpg,image/png,image/gif"
  }

  emptyQueue() {
    noty({ text: 'Please provide an image file' })
  }
}
