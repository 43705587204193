import { Controller } from "stimulus"
import posthog from 'posthog-js'
import User from "../lib/User"
import Organization from "../lib/Organization"

export default class extends Controller {
  // static values = { projectKey: String } # TODO: use this when we upgrade to Stimulus 2+

  get projectKey() {
    return this.data.has("projectKey") ? this.data.get("projectKey") : null;
  }

  initialize() {
    if (this.projectKey?.length > 0) {
      posthog.init(this.projectKey, {
        api_host: 'https://eu.i.posthog.com',
        autocapture: false,
        capture_pageleave: false,
        capture_pageview: false,
        disable_session_recording: true,
        person_profiles: 'identified_only',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true
          }
        },
        loaded: (posthog) => this.handlePosthogLoaded(posthog)
      })
    }
  }

  handlePosthogLoaded(posthog) {
    if (!User.exists) return

    this.identifyUser(posthog, User.current)
    const sessionUrl = this.startRecording(posthog)
    this.updateIntegrations(sessionUrl)
    this.identifyOrganization(posthog)
  }

  identifyOrganization(posthog) {
    if (!Organization.exists) return

    posthog.group('organization', Organization.current.id)
  }

  identifyUser(posthog, userContext) {
    posthog.identify(userContext.id, {
      email: userContext.email,
      approver: userContext.approver,
      root: userContext.root
    })
  }

  startRecording(posthog) {
    posthog.startSessionRecording()
    return posthog.get_session_replay_url({ withTimestamp: true })
  }

  updateIntegrations(sessionUrl) {
    if (typeof window.Appsignal !== 'undefined') {
      window.Appsignal.addDecorator((span) => 
        span.setTags({ posthog_session_url: sessionUrl })
      )
    }

    if (typeof window.Intercom !== 'undefined') {
      window.Intercom('update', { posthog_session_url: sessionUrl })
    }
  }
}
