import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    let controller = this
    this.modal.on('hide.bs.modal', () => {
      controller.resetModalBodyHTML()
    })
  }

  get modalBody() {
    return this.modal.find(".modal-body")
  }

  get modal() {
    return $(this.data.get('modal'))
  }

  get formIframe() {
    return `<iframe src="${this.data.get('url')}" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0"></iframe>`
  }

  openPopup() {
    this.modal.modal('show')
    this.setModalBodyHTML(this.formIframe)
  }

  setModalBodyHTML(html) {
    this.data.set('original-content', this.modalBody.html())
    this.modalBody.html(html)
  }

  resetModalBodyHTML() {
    if (this.data.has('original-content')) {
      this.modalBody.html(this.data.get('original-content'))
    }
  }
}
