export class ApplyModeHelper {
  static addNonExistingToSelect2(fields, sites) {
    for (let i = 0; i < fields.length; i++) {
      for (let j = 0; j < sites.length; j++) {
        this.appendOrSelectOption(fields[i], sites[j])
      }
    }
  }

  static setSelectedValue(fields, siteList) {
    let j = 0
    for (let i = 0; i < fields.length; i++) {
      this.appendOrSelectOption(fields[i], siteList[j])
      if (j >= (siteList.length - 1)) {
        j = 0
      } else {
        j = j + 1
      }
    }
  }

  static appendOrSelectOption(field, site) {
    let option = $(field).find("option:contains('" + site + "')").val()
    if (option == undefined) {
      let newSiteOption = new Option(site.url, site.id, true, true)
      $(field).append(newSiteOption).trigger('change')
    } else {
      $(field).select2('trigger', 'select', {
        data: { id: option }
      })
    }
  }

  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  static shuffle(order, array) {
    if (order == 'random') {
      let currentIndex = array.length, randomIndex
      // While there remain elements to shuffle.
      while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
      }
    }
    return array
  }

  static displayErrors(xhr) {
    let errors = xhr.responseJSON.errors
    errors.forEach(error => {
      noty({ text: error })
    })
  }
}
