import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper"

export default class extends Controller {
  static targets = ["deviceFrame"]

  initialize() {
    if (this.setValueOnInit()) {
      this.setDeviceFrameValue(this.data)
    }
  }

  setValueOnInit() {
    return this.data.get('set-value-on-init') == 'true'
  }

  setDeviceFrameValue(data) {
    let value = LocalStorageHelper.storedValue(data)
    if (value != null) {
      $(this.deviceFrameTarget).prop('checked', value === "true")
    }
  }

  storeValue() {
    LocalStorageHelper.storeValue(this.data, this.deviceFrameTarget.checked.toString())
  }
}
