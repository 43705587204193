import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["filterFields", "filterTemplate"];

  initialize() {
    this.filterIndex = 0
    this.loadFilters();
  }

  loadFilters() {
    const filters = JSON.parse(this.data.get("filters"));
    const element = this.data.get("element");
    // if no filters, return
    if(Object.keys(filters).length === 0) return;

    filters['or'].forEach((filterOr) => {
      filterOr['and'].forEach((filterAnd) => {
        this.addFilterToForm(element, filterAnd);
      });
    });
  }

  addFilterToForm(element, filter) {
    this.addContent();
    document.getElementById(`${element}_[${this.filterIndex}][field]`).value = filter.field;
    document.getElementById(`${element}_[${this.filterIndex}][operator]`).value = filter.operator;
    document.getElementById(`${element}_[${this.filterIndex}][value]`).value = filter.value;
    this.filterIndex++;
  }

  addFilter(event) {
    event.preventDefault();
    this.filterIndex++;
    this.addContent();
  }

  addContent() {
    const content = this.filterTemplateTarget.innerHTML.replace(/index/g, this.filterIndex);
    this.filterFieldsTarget.insertAdjacentHTML("beforeend", content);
  }

  removeFilter(event) {
    event.preventDefault();

    const filterFields = event.target.closest(".filter-fields");
    filterFields.remove();
  }
}
