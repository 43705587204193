import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    $(this.element).modal()
  }

  closeModal() {
    $(document).trigger('ad_chooser_modal.closed');
    this.hideModal()
  }

  selectAd(event) {
    event.preventDefault()
    $(document).trigger('ad_chooser_modal.ad_selected', event.currentTarget.dataset.adId);
    this.hideModal()
  }

  hideModal() {
    $(this.element).modal('hide')
  }
}
