import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input']

  toggle() {
    if (this.inputDisabled) {
      this.inputTarget.disabled = false
      this.inputTarget.placeholder = ''
    } else {
      this.inputTarget.disabled = true
      this.inputTarget.value = ''
      this.inputTarget.placeholder = '**********'
    }
  }

  get inputDisabled() {
    return this.inputTarget.disabled
  }
}
