import DatatablesController from "../datatables_controller"

export default class SitesController extends DatatablesController {
  static targets = ["tagsModal", "tagListInput"]

  bulkEdit() {
    var params = { sites_ids: Array.from(this.selected) }
    window.location.href = Routes.edit_bulk_organization_sites_path(App.currentOrganizationId(), params)
  }

  openTagsModal() {
    this.sitesIds = Array.from(this.selected) || []
    this.tagsModal.modal('show')
  }

  submitTagsModal() {
    var sitesParams = {}
    var tagList     = this.tagListInput.val()
    var controller  = this

    $.each(controller.sitesIds, function(index, siteId){
      sitesParams[siteId] = { add_tag_list: tagList }
    })

    $.ajax({
      data: { sites: sitesParams },
      url: Routes.update_bulk_organization_sites_path(App.currentOrganizationId()),
      method: 'put',
      error: function(xhr) {
        alert('Something went wrong')
      },
      success: function (data) {
        controller.dataTable.ajax.reload()
        controller.closeModal()
      }
    })
  }

  closeModal() {
    this.tagsModal.modal('hide')
    this.tagListInput.tagsinput('removeAll')
  }

  get tagsModal() {
    return $(this.tagsModalTarget)
  }

  get tagListInput() {
    return $(this.tagListInputTarget)
  }
}
