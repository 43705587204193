import { Controller } from "stimulus"

export default class extends Controller {
  get articleId() {
    return this.data.get('id')
  }

  show() {
    Intercom('showArticle', this.articleId)
  }
}
