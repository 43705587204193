import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["frames", "inputs", "submit"];
  
  onClickFrame(event){
    this.unselectAllFrames();
    this.selectFrame(event.currentTarget);
  }

  selectFrame(target){
    this.enableSubmitButton();
    this.enableFrameIdInput(target);
    $(target).addClass("capturable-frame-card-selected");
  }

  unselectAllFrames(){
    this.disableSubmitButton();
    this.disableAllFrameIdInputs();
    this.unselectAllFrameCards();
  }

  unselectAllFrameCards(){
    $(this.framesTargets).removeClass("capturable-frame-card-selected");
  }

  disableAllFrameIdInputs(){
    $(this.inputsTargets).prop("disabled", "disabled");    
  }

  disableSubmitButton(){
    this.submitTarget.disabled = true;
  }

  enableSubmitButton(){
    this.submitTarget.disabled = false;
  }

  enableFrameIdInput(target){
    $(target).children("input.frame-id")[0].disabled = false;
  }
}
