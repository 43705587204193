import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["iamCredentialsFields", "s3BucketPolicyFields", "accessKeyId", "secretAccessKey"]

  toggleAccessTypeFields(){
    this.iamCredentialsFieldsTarget.classList.toggle('hidden');
    this.s3BucketPolicyFieldsTarget.classList.toggle('hidden');
    this.accessKeyIdTarget.disabled = !this.accessKeyIdTarget.disabled;
    this.secretAccessKeyTarget.disabled = !this.secretAccessKeyTarget.disabled;
  }
}
