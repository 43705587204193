import DatatablesController from "../datatables_controller"

export default class PendingSetsController extends DatatablesController {
  static targets = [ "assignedFilter", "sourceFilter", "organizationFilter", "refreshButton" ]

  connect() {
    if (this.hasRefreshButtonTarget) {
      this.toggleAutoRefresh()
    }
  }

  filterAssigned() {
    this.filterColumn(this.reviewerColumnIndex, this.assignedFilterValue)
  }

  filterSource(){
    this.filterColumn(this.sourceColumnIndex, this.sourceFilterValue)
  }

  filterOrganization() {
    this.filterColumn(this.organizationColumnIndex, this.organizationFilterValue)
  }

  toggleAutoRefresh() {
    if ( $(this.refreshButtonTarget).is(':checked') ) {
      this.dataTable.ajax.reload()
      this.enableAutoRefresh()
    } else {
      this.disableAutoRefresh()
    }
  }

  enableAutoRefresh() {
    this.refreshId = window.setInterval( () => {
      this.dataTable.ajax.reload()
    }, 60000 );
  }

  disableAutoRefresh() {
    window.clearInterval(this.refreshId)
    this.resetRefreshId()
  }

  resetRefreshId() {
    this.refreshId = null
  }

  get autoRefreshEnabled() {
    return (this.refreshId !== null)
  }

  get reviewerColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'reviewer')
  }

  get sourceColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'source')
  }

  get organizationColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'organization')
  }

  get assignedFilterValue() {
    return $(this.assignedFilterTarget).val()
  }

  get sourceFilterValue() {
    return $(this.sourceFilterTarget).val()
  }

  get organizationFilterValue() {
    return $(this.organizationFilterTarget).val()
  }
}
