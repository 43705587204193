import { Controller } from "stimulus"

export default class extends Controller {

  copy(event) {
    var target = $(event.currentTarget)
    navigator.clipboard.writeText(target.data('clipboard-text'))
    var title = target.data('original-title')
    target.tooltip({ animation: false }).attr('data-original-title', 'copied!').tooltip('show')
    setTimeout(function () {
      target.attr('data-original-title', title)
      target.tooltip('hide')
    }, 1000)
  }
}
