import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper";

export default class extends Controller {
  static targets = ["wrapperType", "container", "osOptionsContainer", "timestampContainer"]

  initialize() {
    if (this.setValueOnInit()) {
      this.setWrapperTypeValue(this.data)
    }
    // passing null as event to toggleContainers to use the stored value
    this.toggleContainers(null)
  }

  setValueOnInit() {
    return this.data.get('set-value-on-init') == 'true'
  }

  setWrapperTypeValue(data) {
    let value = LocalStorageHelper.storedValue(data)
    if (value != null) {
      $(this.wrapperTypeTarget).selectpicker('val', value)
    }
  }

  storeValue() {
    LocalStorageHelper.storeValue(this.data, this.wrapperTypeTarget.value)
  }

  toggleContainers(event) {
    const wrapperTypeValue = event ? event.currentTarget.value : this.wrapperTypeTarget.value
    this.toggleOsContainer(wrapperTypeValue)
    this.toggleTimestampContainer(wrapperTypeValue)
  }

  toggleOsContainer(wrapperTypeValue) {
    if(this.hasOsOptionsContainerTarget) {
      if (wrapperTypeValue == 'os') {
        $(this.osOptionsContainerTarget).show()
      } else {
        $(this.osOptionsContainerTarget).hide()
      }
    }
  }

  toggleTimestampContainer(wrapperTypeValue) {
    if(this.hasOsOptionsContainerTarget) {
      if (wrapperTypeValue == 'os' || wrapperTypeValue == 'device_os') {
        $(this.timestampContainerTarget).show()
      } else {
        $(this.timestampContainerTarget).hide()
      }
    }
  }

  displayContainer(event) {
    if ($(event.currentTarget).is(':checked')) {
      $(this.containerTarget).show()
    } else {
      $(this.containerTarget).hide()
    }
  }
}
