import { Controller } from "stimulus"
import CodeMirror from "codemirror/lib/codemirror"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    if (!this.textareaTarget.dataset.codeEditor) {
      this.initCodeMirror()
      this.highlightLines()
    }
  }

  disconnect() {
    if (this.editor) {
      this.editor.toTextArea()
    }
  }

  initCodeMirror() {
    this.editor = CodeMirror.fromTextArea(this.textareaTarget, {
      lineNumbers: true,
      readOnly: true,
      styleSelectedText: true,
      gutters: ['errors-and-warnings', 'CodeMirror-linenumbers']
    })
    this.textareaTarget.dataset.codemirrorEditor = true
  }

  highlightLines() {
    const errorsAndWarnings = JSON.parse(this.textareaTarget.dataset.errorsAndWarnings || '[]')
    
    errorsAndWarnings.forEach(obj => {
      const colorClass = obj.status === 'warning' ? 'yellow' : 'red'
      const iconClass = obj.status === 'warning' ? 'exclamation-circle' : 'times-circle'

      this.editor.addLineClass(obj.index, 'wrap', `bg-${colorClass}`)

      const marker = document.createElement('div')
      marker.classList.add(colorClass)
      marker.dataset.toggle = 'popover'
      marker.dataset.trigger = 'hover'
      marker.dataset.placement = 'top'
      marker.dataset.content = obj.messages.join('<br>')
      marker.dataset.html = 'true'
      marker.innerHTML = this.faIcon(iconClass)

      $(marker).popover({ animation: false })
      this.editor.setGutterMarker(obj.index, 'errors-and-warnings', marker)
    })
  }

  faIcon(iconClass) {
    return `<i class="fa fa-${iconClass}"></i>`
  }
} 
