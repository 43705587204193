import FilesController from "./files_controller"

export default class extends FilesController {
  static targets = ["input", "submit", "adIds", "previewFrame", "form"]

  acceptedFiles() {
    return "video/mp4,video/mpeg,video/webm"
  }

  emptyQueue() {
    noty({ text: 'Please provide a video file' })
  }

  get previewFrame() {
    return $(this.previewFrameTarget).val()
  }

  onSubmitSuccess() {
    // set preview frame and then, if successful, call the original/regular file submit behavior
    this.setPreviewFrameThenSubmit()
  }

  setPreviewFrameThenSubmit() {
    let controller = this
    $.ajax({
      type: 'PUT',
      url: Routes.set_frame_organization_campaign_video_files_path(App.currentOrganizationId(), controller.campaignId),
      data: { preview_frame: controller.previewFrame, ad_ids: controller.adIds },
      error: function(response) {
        controller.enableSubmitButton()
        noty({ text: response.message })
      },
      success: function(response) {
        controller.createDraftSetAndRedirect()
      }
    })
  }
}
