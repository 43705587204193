import SitesController from "./sites_controller"

export default class extends SitesController {
  get options() {
    return {
      width: '100%',
      placeholder: 'Select site',
      tags: true,
      ajax: {
        delay: 250, // wait 250 milliseconds before triggering the request
        data: function (params) {
          var query = {
            value: params.term,
            page: params.page || 1
          }
          return query
        },
        processResults: function (data) {
          // Transforms the top-level key of the response object from 'data' to 'results'
          return {
            results: $.map(data.results, function (obj) {
              return { id: obj.text, text: obj.text }
            }),
            pagination: data.pagination
          }
        }
      },
      createTag: function (params) {
        var term = $.trim(params.term)

        if (term === '') {
          return null
        }

        return {
          id: term,
          text: term,
          newTag: true
        }
      }
    }
  }

  createSite(data) {
    var controller = this

    $.ajax({
      url: controller.data.get('url-create'),
      dataType: "json",
      type: 'POST',
      data: {
        'sites[]': { 'url': data.text }
      },
      error: function (xhr) {
        let error = xhr.responseJSON.errors.map(function (error) {
          return `URL ${error.url.join(', ')}`
        })
        noty({ text: error })
        controller.select2.val(null).trigger('change') // clear selected option
      },
      success: function (response) {
        let site = response[0]
        var newSiteOption = new Option(site.url, site.url, true, true)
        controller.select2.append(newSiteOption).trigger('change')
      },
      complete: function () {
        data.newTag = false
      }
    })
  }
}
