import DatatablesController from "../datatables_controller"

export default class ScheduledSetsController extends DatatablesController {
  static targets = [ "organizationFilter" ]

  filterOrganization() {
    this.filterColumn(this.organizationColumnIndex, this.organizationFilterValue)
  }

  get organizationColumnIndex() {
    return this.config.columns.findIndex(column => column.data === 'organization')
  }

  get organizationFilterValue() {
    return $(this.organizationFilterTarget).val()
  }
}
