import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["adPreview", "pagexray", "landingPage", "imagePreview", "script", "networkTimeline", "livePreview"]

  initialize() {
    if ( this.hasAdPreviewTarget || this.hasPagexrayTarget || this.hasLandingPageTarget ) {
      this.channel = App.cable.subscriptions.create({ channel: 'AdPreviewChannel', id: this.data.get('previewId') }, {
        connected:    this._connected.bind(this),
        disconnected: this._disconnect.bind(this),
        received:     this._received.bind(this)
      })
    }
  }

  listen() {
    if (this.channel) {
      this.channel.perform('follow', { id: this.data.get('previewId') })
    }
  }

  _connected() {
    this.listen()
  }

  _disconnect() {
    App.cable.subscriptions.remove(this.channel)
  }

  _received(data){
    let targetName = data.target+"Target"
    $(this[targetName]).html(data['rendered_partial'])
  }
}
