import DropzoneController from "../dropzone_controller"
import AdReformDirectUpload from "lib/AdReformDirectUpload"

export default class extends DropzoneController {
  static targets = ["input", "submit", "adIds"]

  attachEvents(dz) {
    this.attachAddedFileEvents(dz)
    this.attachSuccessEvents(dz)
    this.attachRemovedFileEvents(dz)
    this.attachErrorEvents(dz)
  }

  attachAddedFileEvents(dz) {
    dz.on("addedfile", (file) => {
      setTimeout(() => { file.accepted && this.uploadFile(file) }, 500)
    })
  }

  attachSuccessEvents(dz) {
    dz.on('success', (file) => {
      noty({ text: "File uploaded", type: 'success' })
    })

    dz.on("queuecomplete", (file) => {
      this.enableSubmitButton()
    })
  }

  attachErrorEvents(dz) {
    dz.on('error', function (file, error) {
      if(error){
        noty({ text: error, type: 'error'})
      }
      dz.removeFile(file);
    });
  }

  attachRemovedFileEvents(dz) {
    dz.on("removedfile", (file) => {
      file.uploader && $(file.uploader.hiddenInput).remove()
      file.uploader && file.uploader.xhr && file.uploader.xhr.abort()
      if (!this.hasAcceptedFiles) {
        this.disableSubmitButton();
      }
    })
  }

  uploadFile(file) {
    this.disableSubmitButton();
    new AdReformDirectUpload( this.directUploadUrl, this.paramName, this.element, this.dropzone, file ).start()
  }

  get hasAcceptedFiles() {
    return (this.dropzone.getAcceptedFiles().length > 0)
  }

  //ignore html data options, must be false to work properly
  get autoQueue() {
    return false
  }

  storeAdIds(adIds) {
    let idsToAdd = adIds.join(',')
    let appendValue;
    if (this.adIdsTarget.value.length > 0) {
      appendValue = `,${idsToAdd}`
    } else {
      appendValue = idsToAdd
    }
    this.adIdsTarget.value += appendValue
  }

  get adIds() {
    return this.adIdsTarget.value.split(',')
  }

  disableSubmitButton() {
    $(this.submitTarget)
      .attr("disabled", true)
      .addClass('disabled')
  }

  enableSubmitButton() {
    $(this.submitTarget)
      .attr("disabled", false)
      .removeClass('disabled')
  }

  submit(event) {
    event.preventDefault()

    var controller = this
    controller.disableSubmitButton();

    this.disableDropzoneHiddenFileInput()

    if(this.hasAcceptedFiles){
      $.ajax(this.submitParams);
    }else{
      this.emptyQueue()
    }
  }

  get submitDefaultParams(){
    return {
      type: 'POST',
      context: this,
      url: this.dropzone.options.url,
      data: this.submitData,
      dataType: "json",
      error: function (response) {
        this.onSubmitError(response.responseJSON)
      },
      success: function (response) {
        this.storeAdIds(response.ad_ids)
        this.onSubmitSuccess()
      },
      complete: function () {
        this.enableDropzoneHiddenFileInput()
      }
    }
  }

  get submitParams(){
    return {...this.submitDefaultParams, ...this.submitCustomParams}
  }

  get submitCustomParams(){
    return {}
  }

  onSubmitSuccess() {
    this.createDraftSetAndRedirect()
  }

  createDraftSetAndRedirect() {
    var url = Routes.draft_organization_campaign_screenshot_sets_path(App.currentOrganizationId(), this.campaignId)
    var controller = this
    $.ajax({
      data: this.draftSetSubmitParams,
      url: url,
      method: 'POST',
      dataType: 'json',
      error: function (xhr) {
        noty({ text: xhr.responseJSON.error })
      },
      success: function (response) {
        window.location.href = controller.redirectLocation(response.location)
      }
    })
  }

  redirectLocation(location) {
    const params = this.wizard ? { w: 1 } : {}
    return `${location}?${$.param(params)}`
  }

  onSubmitError(response){
    let invalidFiles = this.dropzone.files.filter(file => { return response.blob_signed_ids.includes(file.blob_signed_id)})

    invalidFiles.forEach( invalidFile => {
      invalidFile.status = Dropzone.ERROR
      //empty string so the errorHandler event won't display anything
      this.dropzone.emit("error", invalidFile, "")
    })

    let errors = response.error.split(",")
    errors.forEach( error => {
      noty({ text: error})
    })

    this.enableSubmitButton()
  }

  disableDropzoneHiddenFileInput(){
    // we must prevent the file from being submitted, since our form will be submitted with a hidden field with `blob.signed_id` as value
    // we cannot remove it cause dropzone will manipulate it in some internal event handlers
    this.dropzone.hiddenFileInput.disabled = true
  }

  enableDropzoneHiddenFileInput(){
    //we have to enable it again so the element will be 'clickable'
    this.dropzone.hiddenFileInput.disabled = false
  }

  get submitData() {
    return $(this.element).serialize();
  }


  get draftSetSubmitParams() {
    var params = $.param({ad_ids: this.adIds})
    return params
  }

  get campaignId(){
    return this.data.get("campaign-id")
  }

  get directUploadUrl(){
    return this.data.get("direct-upload-url")
  }

  get paramName(){
    return this.data.get("paramName")
  }

  get wizard() {
    return (this.data.get("wizard") == "true")
  }
}
