import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "frames", 
    "video", 
    "asset",
    "performanceMetrics"
  ]

  connect() {
    this.channel = App.cable.subscriptions.create(
      { channel: "AdChannel", ad_id: this.data.get('adId') },
      {
        connected: () => console.log('AdChannel connected'),
        disconnected: () => console.log('AdChannel disconnected'),
        received: (data) => this.handleMessage(data)
      }
    )
  }

  disconnect() {
    if (this.channel) {
      App.cable.subscriptions.remove(this.channel)
    }
  }

  handleMessage(data) {
    if (this.hasAssetTarget) this.updateContentFromData(data.asset, this.assetTarget)
    if (this.hasFramesTarget) this.updateContentFromData(data.frames, this.framesTarget)
    if (this.hasPerformanceMetricsTarget) this.updateContentFromData(data.performance_metrics, this.performanceMetricsTarget)
    if (this.hasVideoTarget) this.updateContentFromData(data.video, this.videoTarget)
    App.initTooltipsAndPopovers()
  }

  updateContentFromData(templateData, target) {  
    if (templateData && target) {
      target.innerHTML = templateData
    }
  }

  handleGenerateScreenshotsButton(data) {
    const button = this.generateScreenshotsButton
    if (!button || !button.classList.contains('disabled')) return
    console.log(`button.dataset.enabledText: ${button.dataset.enabledText}`)

    if (data.status === 'uploaded') {
      button.innerHTML = window.faicon('plus', { text: button.dataset.enabledText })
      button.classList.remove('disabled')
    }
  }

  get generateScreenshotsButton() {
    return document.querySelector(`[data-ad-id="${this.data.get('adId')}"][data-target="ad-channel.generateScreenshotsButton"]`)
  }
}
