export default {
  getContext() {
    const context = document.querySelector('meta[name="user-context"]');
    if (context && context instanceof window.HTMLMetaElement) {
      return context.dataset;
    }
    return null;
  },

  get exists() {
    return this.getContext() != null;
  },

  get current() {
    return this.exists ? this.getContext() : {}
  }
};
