import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "newFormPlaceholder"]
  
  connect() {
    this.initializeTagsInputs()
  }
  
  addSite(event) {
    event.preventDefault()
    
    fetch(Routes.site_fields_organization_sites_path(App.currentOrganizationId()), {
      headers: { Accept: "text/html" }
    })
    .then(response => response.text())
    .then(html => {
      this.newFormPlaceholderTarget.insertAdjacentHTML('beforebegin', html)
      this.initializeLastTagsInput()
    })
  }
  
  removeSite(event) {
    event.preventDefault()
    const nestedFields = event.target.closest('.nested-site-fields')
    if (nestedFields) {
      nestedFields.remove()
    }
  }
  
  initializeTagsInputs() {
    this.tagsInputs.forEach(input => {
      $(input).tagsinput()
    })
  }
  
  initializeLastTagsInput() {
    const lastTagInput = this.tagsInputs[this.tagsInputs.length - 1]
    if (lastTagInput) {
      $(lastTagInput).tagsinput()
    }
  }

  get tagsInputs() {
    return this.formTarget.querySelectorAll('[data-role="tagsinput"]')
  }
} 
