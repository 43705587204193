import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  get options() {
    return {};
  }

  get defaultOptions() {
    return {
      multiple: false,
      ajax: {
        url: this.data.get('url'),
        dataType: 'json',
        data: function (params) {
          var query = {
            search: params.term
          }
          // Query parameters will be ?search=[term]
          return query
        },
        processResults: function (data) {
          // Transforms the top-level key of the response object from 'data' to 'results'
          return {
            results: data
          }
        }
      },
    }
  }

  get mergedOptions() {
    return $.extend(true, this.defaultOptions, this.options);
  }

  initialize() {
    this.select2 = $(this.inputTarget).select2(this.mergedOptions)
  }

  disconnect() {
    this.select2.select2('destroy')
  }
}
