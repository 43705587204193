import { Controller } from "stimulus"

export default class extends Controller {

  createDraftScreenshotSet(event) {
    var $button = $(event.target)

    $button.addClass('disabled').prop('disabled', true)

    var campaignId = this.data.get('campaign-id')
    var params = { ad_ids: JSON.parse(this.data.get('ad-ids')) }
    var url = Routes.draft_organization_campaign_screenshot_sets_path(App.currentOrganizationId(), campaignId)

    $.ajax({
      data: params,
      url: url,
      method: 'POST',
      dataType: 'json',
      error: function (xhr) {
        $button.removeClass('disabled').prop('disabled', false)
        noty({ text: xhr.responseJSON.error })
      },
      success: function (response) {
        window.location.href = response.location
      }
    })
  }

}
