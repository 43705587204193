import { Controller } from "stimulus"

export default class extends Controller {
  run(event) {
    event.preventDefault()
    var target = $(event.currentTarget)
    navigator.clipboard.writeText(this.data.get('text'))
    window.open(this.data.get('url'), '_blank')
  }
}
