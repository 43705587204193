import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["legend", "container"]
  initialize() {
    /** pass HAR and options to `newPerfCascadeHar` to generate the SVG element*/
    const perfCascadeSvg = perfCascade.fromHar(JSON.parse(this.data.get("harJson")), {
      showIndicatorIcons: false,
      legendHolder: this.legendTarget
    })
    /** append SVG to page - that's it */
    $(this.containerTarget).html(perfCascadeSvg)
  } 
}
