import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["disabler", "enabler", "inputContainer", "input"]

  initialize() {
    let controller = this
    controller.toggle()
    $(this.enablerTarget).on('change', function() {
      controller.toggle()
    })
  
    $(this.disablerTarget).on('change', function() {
      controller.toggle()
    })
  }

  toggle() {
    if (this.enableIsChecked) {
      $(this.inputContainerTarget).show()
      $(this.inputTarget).prop('disabled', false)
    }
    
    if (this.disableIsChecked) {
      $(this.inputContainerTarget).hide()
      $(this.inputTarget).prop('disabled', true)
    }
  }

  get enableIsChecked() {
    return $(this.enablerTarget).is(':checked')
  }

  get disableIsChecked() {
    return $(this.disablerTarget).is(':checked')
  }
}
