import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["alert"]

  async ignore(event) {
    event.preventDefault()
    const alert = this.alertTarget
    const path = alert.dataset.ignorePath
    console.log('path', path)

    try {
      const response = await fetch(path, {
        method: 'PUT',
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
          "Accept": "application/json"
        },
        credentials: 'same-origin'
      })
      
      if (response.ok) {
        alert.remove()
        this.notifySuccess()
      } else {
        this.notifyError()
      }
    } catch (error) {
      this.notifyError()
    }
  }

  notifySuccess() {
    noty({ text: 'Message ignored', type: 'information' })
  }

  notifyError() {
    noty({ text: 'We had trouble ignoring the message. If it persists, please contact support@adreform.com' })
  }
}
