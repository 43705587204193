import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    const customConfig   = this.data.has('config') ? JSON.parse(this.data.get('config')) : {}
    this.config          = $.extend(true, this.defaultConfig(), customConfig)
    this.selectpickerApi = $(this.element).selectpicker(this.config)
    this.setInitialValue()
  }

  setInitialValue() {
    if ( this.data.has('value') ) {
      this.selectpickerApi.selectpicker('val', this.data.get('value'));
    }
  }

  defaultConfig() {
    return {
      iconBase: 'fa',
      tickIcon: 'fa-check',
      showTick: true,
      width: 'fit'
    }
  }
}
