import Select2Controller from "../select2_controller"

export default class extends Select2Controller {

  get options() {
    return {
      placeholder: '(optional) Associate this campaign with a client',
      tags: true,
      createTag: function (params) {
        var term = $.trim(params.term)

        if (term === '') {
          return null
        }

        return {
          id: term,
          text: term,
          newTag: true // add additional parameters
        }
      }
    }
  }

  initialize() {
    this.select2 = $(this.inputTarget).select2(this.mergedOptions)
    this.bindEvents()
  }

  bindEvents() {
    var controller = this
    this.select2.on('select2:select', function (e) {
      var data = e.params.data
      if (data.newTag) {
        $.ajax({
          url: controller.data.get('url-create'),
          dataType: "json",
          type: 'POST',
          data: {
            'client': { 'name': data.text }
          },
          error: function (xhr) {
            noty({ text: xhr.responseText })
            // clear selected option
            controller.select2.val(null).trigger('change')
            data.newTag = false
          },
          success: function (response) {
            var newOptionForNewTag = new Option(response.name, response.id, true, true)
            controller.select2.append(newOptionForNewTag).trigger('change')
            data.newTag = false
          }
        })
      }
    })
  }
}
