import DatatablesController from "../datatables_controller"

export default class AdsController extends DatatablesController {

  initialize() {
    var config = JSON.parse(this.data.get('config'))
    var customButtons = {
      buttons: {
        buttons: [
          {
            extend: 'collection',
            text: 'Export',
            className: 'hidden',
            buttons: [
              {
                extend: 'csvHtml5',
                text: faicon('table', { text: 'CSV' }),
                exportOptions: {
                  columns: ['ad_name_or_id:name', 'external_id:name', 'landing_page_url:name', 'kind:name', 'screenshot_count:name', 'created_at:name']
                }
              },
              {
                extend: 'excelHtml5',
                text: faicon('file-excel-o', { text: 'Excel' }),
                exportOptions: {
                  columns: ['ad_name_or_id:name', 'external_id:name', 'landing_page_url:name', 'kind:name', 'screenshot_count:name', 'created_at:name']
                }
              }
            ]
          }
        ]
      }
    }
    var customConfig = $.extend(true, config, customButtons)
    this.data.set('config', JSON.stringify(customConfig));

    super.initialize()
  }

  createDraftScreenshotSet(event) {
    var $button = $(event.target)

    $button.addClass('disabled').prop('disabled', true)

    var campaignId = this.data.get('campaign-id')
    var params = { ad_ids: this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
    }
    var url = Routes.draft_organization_campaign_screenshot_sets_path(App.currentOrganizationId(), campaignId)

    $.ajax({
      data: params,
      url: url,
      method: 'POST',
      dataType: 'json',
      error: function (xhr) {
        $button.removeClass('disabled').prop('disabled', false)
        noty({ text: xhr.responseJSON.error })
      },
      success: function (response) {
        window.location.href = response.location
      }
    })
  }

  bulkRecapture(event) {
    event.preventDefault()
    var $button = $(event.target)

    $button.addClass('disabled').prop('disabled', true)

    var campaignId = this.data.get('campaign-id')
    var params = { ad_ids: this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
    }

    var url = Routes.recapture_bulk_organization_campaign_ads_path(App.currentOrganizationId(), campaignId)

    $.ajax({
      data: params,
      url: url,
      method: 'POST',
      dataType: 'json',
      error: function(xhr) {
        $button.removeClass('disabled').prop('disabled', false)
        noty({ text: xhr.responseJSON.error })
      },
      success: function(response) {
        noty({ text: response.message, type: 'success' })
        window.location.reload()
      }
    })
  }

  download() {
    var campaignId = this.data.get('campaign-id')
    var params = { 'ids': this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
    }

    var url = Routes.download_bulk_organization_campaign_ads_path(App.currentOrganizationId(), campaignId, params)
    window.location.href = url
  }

  exportCSV() {
    this.dataTable.button('.buttons-csv').trigger();
  }

  exportExcel() {
    this.dataTable.button('.buttons-excel').trigger();
  }
}
