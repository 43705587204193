import { Controller } from "stimulus";
import { ApplyModeHelper } from "../lib/apply_mode_helper";

export default class extends Controller {
  static targets = ["list"]

  initialize() {
    var controller = this
    $(this.listTarget).on('change', (e) => {
      controller.fetchSitesFromList(e)
    });
  }

  fetchSitesFromList(event) {
    var list = $(event.target).val()
    if (list != '') {
      var controller = this
      $.ajax({
        type: 'GET',
        data: { limit: controller.siteFields.length },
        url: Routes.members_organization_site_list_path(App.currentOrganizationId(), list),
        dataType: 'json',
        success: function (sites) {
          ApplyModeHelper.addNonExistingToSelect2(controller.siteFields, sites)
          ApplyModeHelper.setSelectedValue(controller.siteFields, sites)
        },
        error: function (xhr) {
          ApplyModeHelper.displayErrors(xhr)
        }
      })
    }
  }

  get siteFields() {
    return $("[data-target='select2--sites.input']")
  }
}
