import DropzoneController from "./dropzone_controller"

export default class extends DropzoneController {

  acceptedFiles() {
    return "image/jpeg,image/jpg,image/png,image/gif"
  }

  emptyQueue() {
    noty({ text: 'Please provide an image file' })
  }

  submitForm(controller, target, formData) {
    formData.append("screenshot[site_id]", $('#screenshot_site_id').val())
    formData.append("screenshot[device_id]", $('#screenshot_device_id').val())
    formData.append("screenshot[requester_id]", $('#screenshot_requester_id').val())
    if ($("#screenshot_notify").is(':checked')) {
      formData.append("screenshot[notify]", true)
    }
    formData.append("add_another", target.data("add-another"))
  }
}
