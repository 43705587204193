import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "label"]

  updateLabel(){
    var labelSuffix = '';

    if (this.data.has("label-sufix")) {
      labelSuffix = this.data.get("label-sufix")
      if (this.inputTarget.value > 1) {
        if (this.data.has("label-sufix-plural")) {
          labelSuffix = this.data.get("label-sufix-plural");
        } else {
          labelSuffix += 's';
        }
      }
    }

    this.labelTarget.innerHTML = this.inputTarget.value +" "+ labelSuffix;
  }
}
