import FilesController from "./files_controller"

export default class extends FilesController {
  acceptedFiles() {
    return ".zip,application/zip,application/x-zip-compressed," +
           "multipart/x-zip,application/octet-stream,application/x-compressed"
  }

  emptyQueue() {
    noty({ text: 'Please provide a zip file' })
  }
}
