import FilesController from "./files_controller"

export default class extends FilesController {
  static targets = ["input", "logo"]

  acceptedFiles() {
    return "image/jpeg,image/jpg,image/png,image/gif"
  }

  emptyQueue() {
    noty({ text: 'Please provide an image file' })
    this.enableDropzoneHiddenFileInput();
  }

  get submitData() {
    return new FormData(this.element);
  }

  get submitCustomParams() {
    return { 
      processData: false,
      contentType: false
     }
  }
}
