import { DirectUpload } from "@rails/activestorage"

export default class AdReformDirectUpload {
  constructor(directUploadUrl, paramName, form, dropzone, file) {
    this.form = form
    this.paramName = paramName
    this.dropzone = dropzone
    this.file = file
    this.directUpload = new DirectUpload(file, directUploadUrl, this)
  }

  start() {
    this.file.uploader = this
    this.directUpload.create((error, blob) => {
      if (error) {
        this.emitDropzoneError("We had trouble uploading that file. Please try again or contact support@adreform.com")
      } else {
        this.hiddenInput = this.createBlobInputHidden(blob.signed_id)
        this.file.blob_signed_id = blob.signed_id
        this.emitDropzoneSuccess()
      }
    })
  }

  createBlobInputHidden(value) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = this.paramName
    hiddenField.value = value
    this.form.appendChild(hiddenField)

    return hiddenField
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.file.previewTemplate
    const progress = event.loaded / event.total * 100
    element.querySelector(".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.dropzone.emit("error", this.file, error)
    this.dropzone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.dropzone.emit("success", this.file)
    this.dropzone.emit("complete", this.file)
  }
}
