import DropzoneController from "./dropzone_controller"

export default class extends DropzoneController {
  static targets = ["input"]

  acceptedFiles() {
    return ".csv,text/csv,text/plain,application/vnd.ms-excel,application/octet-stream"
  }

  emptyQueue() {
    noty({ text: 'Please provide a CSV file' })
  }

  submitForm(controller, target, formData) {
    var fields = $(controller.element).serializeArray()
    $.each(fields, function (i, field) {
      formData.append(field.name, field.value);
    });
  }
}
