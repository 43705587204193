import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.channel = App.cable.subscriptions.create({ channel: 'NotificationChannel' }, {
      received(data) {
        let html = new DOMParser().parseFromString( data['notification'], 'text/html');
        const notificationHtml = html.body.firstChild;

        noty({
          text:    notificationHtml,
          layout:  'bottomLeft',
          type:    'notification',
          closeWith: ['click', 'button'],
          timeout: false
        });
      }
    })
  }

  disconnect() {
    App.cable.subscriptions.remove(this.channel)
  }
}
