import SitesController from "./select2/sites_controller"
import { ApplyModeHelper } from "../lib/apply_mode_helper";

export default class extends SitesController {
  static targets = ["list", "order", "siteTag", "tagList"]

  // override SitesController initialize
  initialize() {
    this.initializeSelect2()
    this.bindEvents()
  }

  initializeSelect2() {
    this.select2 = $(this.siteTagTarget).select2({
      width: '100%',
      placeholder: 'Uses sites from a tag',
      dropdownParent: $('#smart-site-modal'),
      ajax: {
        delay: 250, // wait 250 milliseconds before triggering the request
        url: Routes.search_organization_site_tags_path(App.currentOrganizationId()),
        dataType: 'json',
        data: function (params) {
          var query = {
            value: params.term
          }
          return query
        }
      },
      templateResult: function (data) {
        var result = $("<span>" + data.text +"</span>")
        var label = data.count == 1 ? 'site' : 'sites'
        var small = $("<small style='color: gray'> " + data.count + " " + label + "</small>")
        result.append(small)

        return result
      }
    })
  }

  bindEvents() {
    var controller = this
    this.select2.on('select2:select', function (e) {
      controller.filterSiteTag(e)
    })
  }

  setOrder(event) {
    this.order = $(event.currentTarget).val()
  }

  copySites() {
    var siteList = $(this.listTarget).val().split(/\n/)
    // remove empty elements from an array
    siteList = siteList.filter(function (el) { return el; })

    if (siteList.length > 0) {
      this.createSites(this.siteFields, siteList, this.order)
      $(this.element).modal('hide')
    } else {
      noty({ text: 'Please provide a site' })
    }
  }

  createSites(fields, sitelist, order) {
    var controller = this
    var sitesData = []
    for (let i = 0; i < sitelist.length; i++) {
      sitesData.push({ url: sitelist[i] })
    }

    $.ajax({
      type: 'POST',
      url: Routes.create_bulk_organization_sites_path(App.currentOrganizationId()),
      data: JSON.stringify({ 'sites': sitesData, 'tag_list': controller.tagList }),
      contentType: 'application/json',
      dataType: 'json',
      success: function (sites) {
        ApplyModeHelper.addNonExistingToSelect2(fields, sites)
        ApplyModeHelper.shuffle(order, sitelist)
        ApplyModeHelper.setSelectedValue(fields, sitelist)
      },
      error: function (xhr) {
        ApplyModeHelper.displayErrors(xhr)
      }
    })
  }

  filterSiteTag(event) {
    var tagsValue = $(event.target).val()
    if (tagsValue != null) {
      var controller = this
      $.ajax({
        type: 'GET',
        url: Routes.search_organization_sites_path(App.currentOrganizationId()),
        dataType: 'json',
        data: $.param({ value: tagsValue, strategy: 'tags' }),
        success: function (data) {
          var sites = ""
          $.map(data.results, function (obj) {
            sites = sites + obj.text + "\n"
          })
          $(controller.listTarget).val(sites).trigger('focus')
        }
      })
    }
  }

  clearContent() {
    $(this.siteTagTarget).val('').trigger('change')
    $(this.listTarget).val('')
    $(this.tagListTarget).tagsinput('removeAll')
    this.order = 'sequential'
    $(this.orderTargets).filter("[value='" + this.order + "']").prop("checked", true)
  }

  get siteFields() {
    return $("[data-target='select2--sites.input']")
  }

  get tagList() {
    return $(this.tagListTarget).val()
  }
}
