import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    var controller = this;
    var checkAdBlock = new FuckAdBlock({checkOnLoad: false, resetOnEnd: true});
    checkAdBlock.check(true);
    checkAdBlock.onDetected(function() {
      controller.notifyUser();
      controller.notifyIntercom();
    });
  }

  notifyUser() {
    var controller = this;
    noty({
      text: controller.getMessage(),
      layout: 'bottomLeft',
      type: 'warning',
      closeWith: ['click', 'button'],
      timeout: false
    });
  }

  getMessage() {
    return "<div>👋 It looks like you're using an ad blocker. Please exclude our domain from your ad blocker to ensure that our service works as expected. " + this.getKBButton() +"<div>";
  }

  getKBButton() {
    return "<br><a href='" + this.getKnowledgeBaseUrl() + "' target='_blank' class='btn btn-warning btn-sm' role='button'>Learn more</a>";
  }

  getKnowledgeBaseUrl() {
    return "http://help.adreform.com/en/articles/4281510";
  }

  notifyIntercom() {
    Intercom('trackEvent', 'received ad block notice');
  }
}
