export const LocalStorageHelper = {
  storedValue(data) {
    var retrievedObject = this.getStoredValue(data);
    if (!this.isJsonLocalStorage(data)) {
      this.convertStringToJson(data);
      retrievedObject = this.getStoredValue(data);
    }

    // when the local storage is empty
    if (retrievedObject === null) {
      return null;
    }

    var parsedConfig = JSON.parse(retrievedObject);
    return parsedConfig[App.currentOrganizationId()];
  },

  isJsonLocalStorage(data) {
    try {
      var retrievedObject = this.getStoredValue(data);
      JSON.parse(retrievedObject);
    } catch (e) {
      return false;
    }
    return true;
  },

  convertStringToJson(data) {
    var value = { [App.currentOrganizationId()]: this.getStoredValue(data) };
    localStorage.setItem(this.getKey(data), JSON.stringify(value));
  },

  getStoredValue(data) {
    var value = localStorage.getItem(this.getKey(data));
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/getItem#return_value
    if (value === null) {
      value = this.getDefaultValue(data);
    }
    return value;
  },

  getKey(data) {
    return data.get('key');
  },

  getDefaultValue(data) {
    return data.get('default-value');
  },

  storeValue(data, value) {
    var keys = this.storedKeyValue(data) || {};

    keys[App.currentOrganizationId()] = value;
    this.setStoredValue(data, JSON.stringify(keys));
  },

  storedKeyValue(data) {
    var retrievedObject = this.getStoredValue(data);
    if (this.isJsonLocalStorage(data)) {
      return JSON.parse(retrievedObject) || {};
    } else {
      // when the local storage is empty
      // we need to return an object with the current organization id
      if (typeof retrievedObject === 'string') {
        retrievedObject = { [App.currentOrganizationId()]: retrievedObject };
      }
      return retrievedObject || {};
    }
  },

  setStoredValue(data, value) {
    localStorage.setItem(this.getKey(data), value);
  }
};
