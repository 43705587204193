import DropzoneController from "./dropzone_controller"

export default class extends DropzoneController {
  static targets = ["input"]

  acceptedFiles() {
    return "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  }

  emptyQueue() {
    noty({ text: 'Please provide a PPT or PPTX file' })
  }

  submitForm(controller, target, formData) {
    var fields = $(controller.element).serializeArray()
    $.each(fields, function (i, field) {
      formData.append(field.name, field.value);
    });
  }
}
