import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]

  initialize() {
    this.parseUrlParams();
  }

  connect() {
    this.setFieldValues();
  }

  setFieldValues() {
    // if a utm param matches a field's name, set value of that field to the param value
    var self = this;
    this.fieldTargets.forEach(function(field) {
      var fieldValue = self.getItem(field.name);

      if (fieldValue) {
        field.value = fieldValue;
      } else {
        field.value = '';
      }
    });
  }

  parseUrlParams() {
    var self = this;
    var location = window.location.toString();
    var querystring = location.split("?");
    if (querystring.length > 1) {
      var pairs = querystring[1].split("&");
      pairs.forEach(function(pair) {
        var name, value;
        [name, value] = pair.split("=");
        self.setItem(name, value)
      });
    }
  }

  setItem(key, value) {
    if (sessionStorage.getItem('utm-' + key) === null) {
      sessionStorage.setItem('utm-' + key, value);
    }
  }

  getItem(key) {
    return sessionStorage.getItem('utm-' + key);
  }
}

