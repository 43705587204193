import DatatablesController from "../datatables_controller"

export default class DeliveriesController extends DatatablesController {

  resend(event) {
    var params = { delivery_id: event.target.dataset.id }
    var url = Routes.resend_organization_webhook_path(App.currentOrganizationId(), event.target.dataset.webhookId)
    $.ajax({
      data: params,
      url: url,
      method: 'POST',
      dataType: 'json',
      error: function (xhr) {
        noty({ text: xhr.responseJSON.error })
      },
      success: function (response) {
        noty({ type: 'success', text: response.message })
        window.location.href = response.location
      }
    })
  }
}
