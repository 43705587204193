import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "startDate"]

  connect() {
    this.daterangepicker = this.createDateRangePicker()
  }

  createDateRangePicker() {
    var options = JSON.parse(this.data.get('options'))
    var startDate = this.hasStartDateTarget && this.startDateTarget.value != '' ? moment(this.startDateTarget.value) : moment()

    $(this.inputTarget).daterangepicker(options)

    var format = options["locale"]["format"]
    this.bindEvent(format);
    this.setDefaultValues(this, startDate, format);
  }

  bindEvent(format) {
    var controller = this
    $(this.inputTarget).on('apply.daterangepicker', function (ev, picker) {
      controller.setDefaultValues(controller, picker.startDate, format);
    })
  }

  setDefaultValues(controller, start, format) {
    $('#' + controller.inputTarget.id + ' span').html(start.format(format));
    // ISO8601 without convert to UTC
    $(controller.data.get('hidden')).val(start.format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ'))
  }
}
