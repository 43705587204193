import { Controller } from "stimulus"
import { LocalStorageHelper } from "../lib/local_storage_helper"

export default class extends Controller {
  static targets = ["startDate", "recaptureAds", "adSelect", "deviceSelect", "defaultDeviceSelector"]

  initialize() {
    let tomorrow = new Date()
    tomorrow.setHours(0,0,0,0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    new DateTimePicker({
      datePickerSelector: '[data-is=scheduled-on-date-input]',
      hiddenInputSelector: '[data-is=scheduled-on-input]',
      initDate: tomorrow,
      timePickerSelector: '[data-is=scheduled-on-time-input]',
      datepicker: {
        startDate: tomorrow
      }
    })

    if(this.hasStartDateTarget) {
      this.bindDatePicker(this.startDateTarget)
    }

    if(this.hasRecaptureAdsTarget) {
      $(this.recaptureAdsTarget).closest('.recapture-ads-wrapper').hide()
    }

    $(this.element).on('cocoon:after-insert', (event, item) => {
      this.setDeviceForNewSiteField(item)
    })
  }

  connect() {
    if (this.hasDefaultDeviceSelectorTarget) {
      const savedDeviceMode = LocalStorageHelper.storedValue(
        { get: (key) => key === 'key' ? 'screenshot_request.default_device' : null }
      )

      // Get all enabled options
      const enabledOptions = $(this.defaultDeviceSelectorTarget).find('option:not(:disabled)').map(function() {
        return this.value;
      }).get();

      // Set the value to savedDeviceMode if it's enabled, otherwise use 'automatic' or the first enabled option
      let valueToSet = 'automatic';
      if (enabledOptions.includes(savedDeviceMode)) {
        valueToSet = savedDeviceMode;
      } else if (!enabledOptions.includes('automatic')) {
        valueToSet = enabledOptions[0] || '';
      }

      $(this.defaultDeviceSelectorTarget).selectpicker('val', valueToSet);
      $(this.defaultDeviceSelectorTarget).selectpicker('refresh');

      // Trigger updateAllDeviceSelections after setting the initial value
      this.updateAllDeviceSelections()
    }
  }

  bindDatePicker(element) {
    // The next weekday after today
    let nextWeekday  = this.getNextWeekday(moment())

    $(element).daterangepicker({
      singleDatePicker: true,
      autoApply: true,
      showDropdowns: true,
      minDate: nextWeekday,
      // The result of tomorrow.format('YYYY') is a string representation of the year.
      // The second argument to parseInt, 10, specifies the radix (base) for the conversion.
      // E.g.: Today is 2024-06-04 => nextWeekday.format('YYYY') is 2024 => parseInt(nextWeekday.format('YYYY'),10) + 1 is 2025
      maxYear: parseInt(nextWeekday.format('YYYY'),10) + 1,
      isInvalidDate: function(date) {
        return date.isoWeekday() > 5
      },
    })
  }

  siteListModal(event) {
    event.preventDefault()
    
    $.ajax({
      url: Routes.new_static_modal_organization_site_lists_path(App.currentOrganizationId()),
      method: 'GET',
      success: function () {
        App.initSelectPickers()
      }
    })
  }

  getNextWeekday(date) {
    // Clone the original date + add 1 day
    let nextDate = moment(date).add(1, 'days');

    // Add 1 day to the current date until the weekday is between 1 (Monday) and 5 (Friday)
    while (nextDate.isoWeekday() > 5) {
        nextDate.add(1, 'days');
    }

    return nextDate;
  }

  toggleRecaptureAd(event) {
    // get the value of the selected option
    let value = event.target.value;
    // find the wrapper element
    let wrapper = $(this.recaptureAdsTarget).closest('.recapture-ads-wrapper')
    if (value === 'never') {
      wrapper.hide()
    } else {
      wrapper.show()
    }
  }

  adChanged(event) {
    this.updateDeviceSelection(event.target)
  }

  updateDeviceSelection(adSelect, specificDeviceSelect = null) {
    const adId = adSelect?.value
    if (!adId) return

    const deviceMode = this.defaultDeviceSelectorTarget.value

    if (deviceMode === 'automatic') {
      this.setSmartDevice(adSelect, specificDeviceSelect)
    } else {
      this.setSpecificDevice(deviceMode, adSelect, specificDeviceSelect)
    }
  }

  setSmartDevice(adSelect, specificDeviceSelect = null) {
    const adDimensions = this.getAdDimensions(adSelect.value, adSelect)
    const deviceKinds = this.adSizeDeviceKindMap[adDimensions] || []

    if (!Array.isArray(deviceKinds)) {
      console.error(`Invalid device kinds for dimensions ${adDimensions}:`, deviceKinds)
      return
    }
    this.updateDeviceSelectOptions(deviceKinds, adSelect, specificDeviceSelect)
  }

  setSpecificDevice(deviceId, adSelect, specificDeviceSelect = null) {
    const deviceSelect = specificDeviceSelect || this.findDeviceSelect(adSelect)

    if (!deviceSelect) {
      console.warn("Device select not found")
      return
    }

    $(deviceSelect).selectpicker('val', [deviceId])
    $(deviceSelect).selectpicker('refresh')
  }

  setDeviceForNewSiteField(cocoonItem) {
    const adSelect = cocoonItem.parents('[data-controller="ad-chooser"]').find('[data-target="screenshot-sets.adSelect"]').get(0)
    const deviceSelect = cocoonItem.find('[data-target="screenshot-sets.deviceSelect"]').get(0)

    if (deviceSelect) {
      this.updateDeviceSelection(adSelect, deviceSelect)
    }
  }

  setDefaultDevice(event) {
    const selectedMode = event.target.value
    LocalStorageHelper.storeValue(
      { get: (key) => key === 'key' ? 'screenshot_request.default_device' : null },
      selectedMode
    )

    this.updateAllDeviceSelections()
  }

  updateAllDeviceSelections() {
    if (this.hasAdSelectTarget) {
      this.adSelectTargets.forEach(adSelect => {
        this.updateDeviceSelection(adSelect)
      })
    }
  }

  updateDeviceSelectOptions(deviceKinds, adSelect, specificDeviceSelect = null) {
    const deviceSelect = specificDeviceSelect || this.findDeviceSelect(adSelect)

    if (!deviceSelect) {
      console.warn("Device select not found")
      return
    }

    // Get device IDs from deviceKinds
    const deviceIds = deviceKinds.map(kind => this.deviceKindIdMap[kind]).filter(id => id)

    // Randomly pick one device ID if there are multiple, otherwise use the default
    const selectedDeviceId = deviceIds.length > 0 ? deviceIds[Math.floor(Math.random() * deviceIds.length)] : this.defaultDeviceId

    $(deviceSelect).selectpicker('val', [selectedDeviceId])
    $(deviceSelect).selectpicker('refresh')
  }

  findDeviceSelect(adSelect) {
    let deviceSelect = adSelect.closest('.nested-fields').querySelectorAll('[data-target="screenshot-sets.deviceSelect"]')

    return deviceSelect
  }

  getAdDimensions(adId, adSelect) {
    const adOption = adSelect.querySelector(`option[value="${adId}"]`)
    return adOption ? adOption.dataset.dimensions : null
  }

  get adSizeDeviceKindMap() {
    return this.data.has('adSizeDeviceKind') ? JSON.parse(this.data.get('adSizeDeviceKind')) : {}
  }

  get deviceKindIdMap() {
    return this.data.has('deviceKindIdMap') ? JSON.parse(this.data.get('deviceKindIdMap')) : {}
  }

  get defaultDeviceId() {
    return this.data.has('defaultDeviceId') ? this.data.get('defaultDeviceId') : null
  }
}
