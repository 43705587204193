/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Appsignal from "@appsignal/javascript"
import { installErrorHandler } from "@appsignal/stimulus"
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events"
import { plugin as pathDecoratorPlugin } from "@appsignal/plugin-path-decorator"

import User         from "../lib/User"
import Organization from "../lib/Organization"
import App          from "../lib/App"

const appContext = App.getContext()

const appsignal = new Appsignal({
  key: appContext.key,
  revision: appContext.revision
})

appsignal.use(windowEventsPlugin())
appsignal.use(pathDecoratorPlugin())

const userContext = User.getContext()

if (userContext) {
  appsignal.addDecorator((span) => {
    return span.setTags({
      user_id: userContext.id,
      intercom_user_id: userContext.id,
      user_email: userContext.email,
      root: userContext.root,
      approver: userContext.approver
    })
  })
}

const orgContext = Organization.getContext();

if (orgContext) {
  appsignal.addDecorator((span) => {
    return span.setTags({
      organization_id: orgContext.id,
      organization_name: orgContext.name,
      organization_slug: orgContext.slug
    })
  })
}

window.Appsignal = appsignal

const application = Application.start()
installErrorHandler(appsignal, application)
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
