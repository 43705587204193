import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['kind', 'list']

  initialize() {
    var controller = this
    if(this.hasKindTarget) {
      $(this.kindTarget).on('change', (e) => {
        controller.toggleKind(e.target)
      });

      this.toggleKind(this.kindTarget)
    }
  }

  toggleKind(target) {
    var kind = $(target).val()
    if (kind == 'static') {
      $('#dynamic').hide()
      $('#static').show()
    } else {
      $('#static').hide()
      $('#dynamic').show()
    }
  }

  createSiteList(event) {
    event.preventDefault();

    // check if urls are provided
    var urls = $('#sites_list_urls').val()
    if (urls.trim() == '') {
      noty({ text: 'Please provide a list of URLs' })
      return false
    }

    var controller = this
    // Get the form element
    const form = event.target.closest("form");

    $.ajax({
      url: Routes.create_static_modal_organization_site_lists_path(App.currentOrganizationId()),
      method: 'POST',
      dataType: 'json',
      data: $(form).serialize(),
      success: function (siteList) {
        controller.appendOrSelectOption(controller.siteListFields, siteList)
        $('#site-list-modal').modal('hide')
      }, error: function (data) {
        noty({ text: data.responseJSON.error })
      }
    })
  }

  appendOrSelectOption(field, siteList) {
    let option = $(field).find("option:contains('" + siteList + "')").val()
    if (option == undefined) {
      let newSiteOption = new Option(siteList.name, siteList.id, true, true)
      $(field).append(newSiteOption).trigger('change')
    } else {
      $(field).select2('trigger', 'select', {
        data: { id: option }
      })
    }
  }

  get siteListFields() {
    return $("[data-target='select2.input site-lists-apply-mode.list']")
  }
}
