import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["table", "selectAllButton", "deselectAllButton", "bulk", "selectAllInfo", "infoContainer", "infoRows", "selectAllRows", "deselectAllRows"]

  initialize() {
    // override the default `sWrapper` set in app_datatables.coffee
    // to remove container-fluid
    $.extend($.fn.DataTable.ext.classes, {
      sWrapper: "dataTables_wrapper p-0 dt-bootstrap4"
    });

    const dtOptions = this.data.has('config') ? JSON.parse(this.data.get('config')) : {}
    this.config = $.extend(true, this.defaultOptions(), dtOptions)
    this.dataTable = $(this.tableTarget).DataTable(this.config)
    this.bulkDestroyPath = this.data.has('bulk-destroy-path') ? this.data.get('bulk-destroy-path') : ''
    this.selected = new Set()
    this.setRowsSelectable()
    this.initializeBulkTooltips()
    this.resetBulkEdit()
    this.destroyBeforeCache()
    this.hideSelectAllInfo()
  }

  destroyBeforeCache() {
    let controller = this
    document.addEventListener("turbolinks:before-cache", function () {
      controller.destroy()
    })
  }

  initializeBulkTooltips() {
    $.each(this.bulkTargets, function () {
      $(this).tooltip({
        title: 'Select one or more rows to perform this action',
        trigger: 'hover focus',
        animation: false
      })
    })
  }

  disconnect() {
    this.destroy()
  }

  destroy() {
    this.dataTable.destroy()
  }

  defaultOptions() {
    var controller = this
    return {
      drawCallback(settings) {
        if (controller.selectedAllData) {
          controller.currentPageRows.select()
          controller.selectAllRows()
        } else if (controller.hasInfoRowsTarget && controller.infoRowsTarget.text != '') {
          controller.deselectAllRowsPage()
        }

        $(controller.tableSelector).on('page.dt', function () {
          controller.resetBulkEdit()
        });
      },
      language: {
        paginate: {
          next: '&raquo;',
          previous: '&laquo;'
        },
        lengthMenu: "_MENU_ rows per page",
        search: '',
        searchPlaceholder: 'Search',
        processing: '<div class="my-4">Loading...</div>'
      },
      buttons: [],
      serverSide: true,
      processing: true,
      search: true,
      paging: true,
      pagingType: 'simple_numbers',
      pageLength: 10,
      lengthMenu: [5, 10, 25, 50],
      ordering: false,
      info: true,
      select: {
        style: 'multi'
      },
      dom: "<'card border-0'" +
        "<'card-block p-0 pt-3'<'row-fluid'<'alert alert-info d-none'>><'row-fluid'tr>>" +
        "<'card-footer bg-white border-0 px-0'<'float-left'l><'float-right'p>>>",
      retrieve: true
    }
  }

  setRowsSelectable() {
    var controller = this;
    $(controller.tableSelector + ' tbody').on('click', 'tr', function () {
      var rowId = this.id;
      var $row = $(this);

      if ($row.find('td.dataTables_empty').length > 0) {
        // this is the "empty table" message, so don't select any rows
        controller.selected = []
        $row.removeClass('selected')
        return;
      } else if (controller.selected.has(rowId)) {
        controller.selected.delete(rowId)
      } else {
        controller.selected.add(rowId)
      }
      $row.toggleClass('selected')
      controller.hideSelectAllInfo()
      controller.controlBulkEditButton(controller.selected.size)
    });
  }

  get tableSelector() {
    return '#' + this.data.get('id');
  }

  get recordsTotal() {
    return this.dataTable.page.info().recordsTotal
  }

  get currentPageRows() {
    return this.dataTable.rows({ page: 'current' })
  }

  get selectedRows() {
    return this.dataTable.rows({ selected: true }).ids().toArray()
  }

  selectedRowValues(key) {
    return this.dataTable.rows({ selected: true }).data().toArray().map(row => row[key])
  }

  selectAllRowsPage() {
    this.currentPageRows.select()
    this.selected = new Set(this.currentPageRows.ids().toArray())
    this.controlBulkEditButton(this.selected.size)
    this.showSelectAllInfo()
  }

  deselectAllRowsPage() {
    this.currentPageRows.deselect()
    this.resetBulkEdit()
    this.hideSelectAllInfo()
  }

  deleteAll() {
    var controller = this
    var recordCount = this.selectedAllData ? this.recordsTotal : this.selectedRows.length

    if (recordCount > 0) {
      var conf = confirm("Are you sure you want to delete " + recordCount + " record(s)?")
      if (conf) {
        $.ajax({
          url: controller.bulkDestroyPath,
          method: 'DELETE',
          data: controller.destroyAllParams,
          complete: function () {
            window.location.reload()
          }
        })
      }
    }
  }

  resetBulkEdit() {
    if (this.hasDeselectAllButtonTarget) {
      this.selected = new Set()
      this.controlBulkEditButton(0)
    }
  }

  controlBulkEditButton(rowsSelected) {
    if (rowsSelected > 0) {
      $.each(this.bulkTargets, function () {
        if ($(this).hasClass('disabled')) {
          $(this).removeClass('disabled')
          $(this).prop('disabled', false)
          $(this).tooltip('disable')
        }
      })
      this.disableDeselectAll(false)
    } else {
      $.each(this.bulkTargets, function () {
        $(this).addClass('disabled')
        $(this).prop('disabled', true)
        $(this).tooltip('toggleEnabled')
      })
      this.disableDeselectAll(true)
    }
  }

  disableDeselectAll(disable) {
    if (this.hasDeselectAllButtonTarget) {
      if(disable) {
        $(this.deselectAllButtonTarget).addClass('disabled')
      } else {
        $(this.deselectAllButtonTarget).removeClass('disabled')
      }
      $(this.deselectAllButtonTarget).prop('disabled', disable)
    }
  }

  search(event) {
    this.deselectAllRowsPage()
    this.dataTable.search(event.target.value).draw()
  }

  showSelectAllInfo() {
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).removeClass('d-none')
      if (this.recordsTotal > this.selectedRows.length) {
        $(this.infoRowsTarget).html('')
        $(this.infoRowsTarget).text(this.selectedRows.length + ' rows on this page are selected. ')
        $(this.selectAllRowsTarget).removeClass('d-none')
        $(this.selectAllRowsTarget).text('Select all ' + this.recordsTotal + ' rows in this table. ')
        $(this.deselectAllRowsTarget).addClass('d-none')
      } else {
        $(this.infoRowsTarget).html('')
        var alertText = 'All ' + this.recordsTotal + ' rows in this table are selected.'
        $(this.infoRowsTarget).append(alertText, ' ')
        $(this.selectAllRowsTarget).addClass('d-none')
        $(this.deselectAllRowsTarget).addClass('d-none')
      }
    }
  }

  hideSelectAllInfo() {
    this.selectedAllData = false
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).addClass('d-none')
    }
  }

  selectAllRows() {
    this.selectedAllData = true
    $(this.infoRowsTarget).text('All ' + this.recordsTotal + ' rows in this table are selected. ')
    $(this.selectAllRowsTarget).text('')
    $(this.deselectAllRowsTarget).removeClass('d-none')
  }

  deselectAllRows() {
    this.selectedAllData = false
    if (this.hasSelectAllInfoTarget) {
      $(this.selectAllInfoTarget).addClass('d-none')
      this.currentPageRows.deselect()
      this.resetBulkEdit()
    }
  }

  filterColumn(index, value) {
    this.dataTable
      .column( index )
      .search( value )
      .draw();
  }

  get destroyAllParams() {
    var params = { 'ids': this.selectedRows }
    if (this.selectedAllData) {
      params = { all: true }
    }
    return params
  }
}
