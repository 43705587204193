import DropzoneController from "./dropzone_controller"

export default class extends DropzoneController {
  static targets = ["input"]

  acceptedFiles() {
    return "image/jpeg,image/jpg,image/png,image/gif"
  }

  emptyQueue() {
    this.element.submit()
  }

  submitForm(controller, target, formData) {
    var fields = $(controller.element).serializeArray()
    $.each(fields, function (i, field) {
      formData.append(field.name, field.value);
    });
  }
}
