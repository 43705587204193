import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "filtersContainer", "gamSiteWarning", "source"]

  initialize() {
    var controller = this
    $(this.inputTarget).on('change', (e) => {
      controller.toggle(e.target)
    });
    this.toggle(this.inputTarget)

    $(this.sourceTarget).on('change', (e) => {
      controller.toggleGamSiteWarning()
    });
    this.toggleGamSiteWarning()
  }

  toggle(target) {
    var filter = $(target).val()
    if (filter == 'all') {
      $(this.filtersContainerTarget).hide()
    } else {
      $(this.filtersContainerTarget).show()
    }
  }

  toggleGamSiteWarning(){
    if(this.sourceTarget.value == 'gam'){
      $(this.gamSiteWarningTarget).show()
    } else {
      $(this.gamSiteWarningTarget).hide()
    }
  }
}
